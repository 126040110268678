<template>
  <div class="homepage-collections" data-test-id="homepage-collections">
    <div class="homepage-collections__grid">
      <router-link
        to="/featured"
        class="homepage-collections__category-link"
        data-test-id="homepage-collections-featured-link"
      >
        <h2 class="homepage-collections__category-title">
          Featured Collections
        </h2>
        <icon-component
          name="caret"
          title="caret icon"
          class="homepage-collections__category-icon"
        />
      </router-link>
      <post-teaser
        v-for="post in posts"
        :key="post.id"
        :post="post"
        featured-image-size="FeaturedCollection"
      />
    </div>
    <ad-slot mapping="HomeCollections" dynamic />
  </div>
</template>

<script>
import AdSlot from '@/components/Ads/AdSlot.vue';
import PostTeaser from '@/components/Posts/PostTeaser.vue';

export default {
  name: 'HomepageCollections',
  components: {
    AdSlot,
    PostTeaser,
  },
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_homepage-collections';
</style>

<docs>
</docs>
